.navbar {
  background-color: black;
  height: 3.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 1000 !important;
  user-select: none;
}

.navlogo {
  height: 3rem;
  position: absolute;
  left: 0.5rem;
  cursor: pointer;
}

.navlist {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  overflow: hidden;
  will-change: left;
}

.links {
  text-decoration: none;
}

.link {
  text-decoration: none;
  font-weight: 700;
  color: #ffd900;
  text-shadow: 0px 0px 70px black;
  position: relative;
  padding: 0.25rem;
  font-family: luxuryFont;
  border-bottom: 1px solid transparent;
  font-size: 14px;
}

.link:hover {
  border-bottom: 1px solid yellow;
}

.active {
  color: black;
}

.reserveren {
  border-bottom: 1px solid yellow;
}

.hamburger {
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: none;
}

.hamburger.active .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
  top: 6px;
  position: absolute;
}

.hamburger.active .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.active .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
  bottom: 6px;
  position: absolute;
}

.bar {
  width: 100%;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease-in-out;
  will-change: position;
}

.mobileNavOpen {
  left: 0% !important;
}

.languageDropdown {
  width: 2.35rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(42, 42, 42); */
  gap: 1px;
  margin-top: 8px;
}

.flagIcon {
  width: 100%;
  height: 25px;

  height: 1.5rem;
  /* width: 2rem; */
}

.flag {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 90%;
}

.flag:hover {
  background-color: rgb(0, 0, 0);
}

.languageTitle {
  color: rgb(193, 193, 193);
  font-size: 10px;

  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.flag:hover .languageTitle {
  color: white;
}

.langWrap {
  display: flex;
  position: absolute;
  justify-content: center;
  position: absolute;
  right: 1rem;
}

.choose {
  font-family: "Open Sans";
  font-size: 10px;
  display: inline;
  height: 20px;
  margin: auto 0;
  margin-right: 5px;

  color: gray;
  line-height: 10px;
}

@media (max-width: 1250px) {
  .navlist {
    gap: 1rem;
  }
}

@media (max-width: 1130px) {
  .navlist {
    gap: 0.25rem;
  }
}

@media (max-width: 980px) {
  .navlist {
    gap: 0.25rem;
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: black;
    top: 0;
    left: -100vw;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
  }

  .link {
    font-size: 20px !important;
  }
  .hamburger {
    display: flex;
  }

  .navbar {
    justify-content: flex-end;
    padding-right: 2rem;
  }

  .langWrap {
    right: 5rem;
  }
  .languageDropdown {
    margin-top: 0.8rem;
    position: relative;
  }
}
