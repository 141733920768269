.stijlvol {
  background: linear-gradient(
    180deg,
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    black
  );
  padding-top: 10rem;
  overflow: hidden;
}

.hero {
  font-size: 40px;
  color: yellow;
  padding-left: 7rem;
}

.contentWrap {
  display: flex;
  gap: 5rem;
  justify-content: center;
  align-items: flex-start;
  padding-top: 8rem;
  padding-bottom: 10rem;
}

.copy {
  color: yellow;
  width: 40rem;
  font-size: 18px;
}

.imgWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.stijlvolImg {
  width: 40rem;
}

.bekijkCta {
  color: rgb(213, 213, 213);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding-top: 2px;
}

.arrowRight {
  transition: all 0.3s ease-in-out;
}

.bekijkCta:hover .arrowRight {
  transform: translateX(15px);
  color: white;
}

.bekijkCta:hover {
  color: white;
}

@media (max-width: 1400px) {
  .contentWrap {
    flex-direction: column-reverse;
    align-items: center;
  }

  .copy {
    color: yellow;
    width: 60%;
    font-size: 18px;
  }

  .stijlvolImg {
    width: 60%;
    border-radius: 0.4rem;
  }

  .imgWrap {
    align-items: center;
  }
}

@media (max-width: 750px) {
  .copy {
    width: 80%;
  }

  .stijlvolImg {
    width: 80%;
    border-radius: 0.4rem;
  }

  .imgWrap {
    align-items: center;
  }
  .hero {
    padding-left: 3.5rem;
    font-size: 30px;
  }
}

@media (max-width: 500px) {
  .copy {
    width: 90%;
    font-size: 18px;
  }

  .stijlvolImg {
    width: 90%;
    border-radius: 0.4rem;
  }

  .imgWrap {
    align-items: center;
  }
  .hero {
    padding-left: 1rem;
    font-size: 30px;
  }
  .stijlvol {
    padding: 5rem 0rem;
  }
  .contentWrap {
    padding-top: 5rem;
  }
}

@media (max-width: 430px) {
  .copy {
    width: 90%;
    font-size: 18px;
  }

  .stijlvolImg {
    width: 90%;
    border-radius: 0.4rem;
  }

  .imgWrap {
    align-items: center;
  }
  .hero {
    text-align: center;
    font-size: 25px;
    padding-left: 0rem;
  }
  .stijlvol {
    padding: 5rem 0rem;
    padding-bottom: 0rem;
  }
  .contentWrap {
    padding-top: 5rem;
  }
}
