.beleef {
  background-color: black;
  /* background: linear-gradient(180deg, black, rgb(21, 21, 21)); */
  height: 85rem;
  overflow-x: hidden !important;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow-x: hidden !important;
}

.cta {
  position: relative;
  /* width: 15rem; */
  height: 3rem;
  outline: 0;
  border: 1px solid #ffd700;
  background-color: #ffd700;
  color: black;
  font-family: luxuryFont;
  font-weight: 800;
  font-size: 1.5rem;
  border-radius: 0.4rem;
  cursor: pointer;
  transition: all 0.45s ease-in-out;
  text-decoration: none;
  z-index: 10;
  user-select: none;
  padding: 0rem 0.5rem;
}

.cta:hover {
  background-color: #0d0b03;
  color: #ffd700;
  border: black;
  text-shadow: 0px 0px 5px #ffd700;
}
.cta:hover + .vilaBg {
  opacity: 0.4 !important;
  transform: scale(1.6) !important;
}

.cta a:hover {
  background-color: transparent;
  color: #ffd700;
  border: black;
  text-shadow: 0px 0px 5px #ffd700;
}

.cta:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffd700;
  transform-origin: bottom right;
  transition: transform 0.65s ease-out;
}

.cta:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.vilaBg {
  position: absolute;
  opacity: 0.1;
  transition: all 0.5s ease-in-out;
  width: 75%;
  height: 100%;
  border-radius: 2rem;
  object-fit: cover;
}

.overFlowWrap {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .vilaBg {
    width: 97%;
    border-radius: 0.3rem;
  }
  .beleef {
    height: 55rem;
    background-color: black;
  }
}
