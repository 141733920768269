@font-face {
  font-family: luxuryFont;
  src: url(./fonts/CinzelDecorative-Regular.ttf);
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: luxuryFont;
  user-select: none;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: rgb(34, 46, 90);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(25, 32, 59);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(15, 22, 49);
}
