.portfolio {
  position: relative;
  text-align: start;
  display: flex;
  padding-top: 6rem;
  flex-direction: column;
  background-color: black;
  background: linear-gradient(
    180deg,
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    black
  );
}

.flexdiv {
  display: flex;
  align-items: flex-end;
}

.flexdiv h1 {
  color: yellow;
}

.portfolioDesc {
  width: 28rem;
  margin-top: 1rem;
}

.topBlock {
  margin-left: 6rem;
}

.tilesGrid {
  position: relative;
  /* background-color: green; */
  margin-top: 5rem;
  padding: 0rem 5.7rem;
  /* gap: 0.3rem; */
  padding-bottom: 5rem;
}

.tileImg {
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
  margin: 5px;
  border-radius: 0.4rem;
}

.tileImg:hover {
  transform: scale(1.01);
  border: 1px solid black;
  box-shadow: 0px 0px 5px black;
}

@media (max-width: 1000px) {
  .flexdiv h1 {
    width: 100%;
    text-align: center;
  }
  .topBlock {
    margin-left: 0rem;
  }
  .tilesGrid {
    padding: 0rem 2rem;
  }
}

@media (max-width: 500px) {
  .tilesGrid {
    padding: 0rem 15px;
    margin-bottom: 5rem;
    margin-top: 4rem;
  }
}
