.kamersprijzen {
  /* background-color: red; */
  background-color: black;
}

.hero {
  color: yellow;
  padding-top: 6rem;
  padding-left: 7rem;
}

.fiveDoorGrid {
  position: relative;
  margin-top: 10rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 5rem;
  padding-bottom: 5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.doorTile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 0.25rem;
  width: 12.5rem;
}

.doorImg {
  width: 100%;
  height: 100%;
}

.appartmentImg {
  width: 30rem;
  height: 25rem;
  cursor: pointer;
}

.doorName {
  color: yellow;
  font-size: 16px;
  position: relative;
  width: 110%;
  text-align: center;
  cursor: pointer;
}

.appartmentGrid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5rem;
}
.appTitle {
  top: 3rem;
  color: yellow;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .fiveDoorGrid {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .appartmentImg {
    width: 25rem;
    height: 20rem;
  }
}

@media (max-width: 500px) {
  .hero {
    color: yellow;
    padding-top: 6rem;
    padding-left: 0rem;
    /* font-size: 24px; */
    text-align: center;
  }
}

@media (max-width: 430px) {
  .appartmentImg {
    width: 22rem;
    height: 17.5rem;
  }
}
