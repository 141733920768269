.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  height: 100vh;
  width: 100%;
  padding: 0rem 1rem;
  overflow-x: hidden !important;
}

.imgWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.overlay img {
  border-radius: 0.4rem;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 1000px) {
  .overlay {
    padding: 0rem 10px;
  }
}
