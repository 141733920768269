.kamersprijzen {
  /* background-color: red; */
  background-color: black;
  overflow: hidden;
}

.hero {
  color: yellow;
  padding-top: 6rem;
  padding-left: 7rem;
}

.fiveDoorGrid {
  position: relative;
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 3rem;
}

.doorTile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 0.25rem;
  width: 12.5rem;
}

.doorImg {
  width: 100%;
  height: 100%;
}

.appartmentImg {
  width: 30rem;
  height: 25rem;
  cursor: pointer;
}

.doorName {
  color: yellow;
  font-size: 16px;
  position: relative;
  width: 110%;
  text-align: center;
  cursor: pointer;
}

.appartmentGrid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5rem;
}
.appTitle {
  top: 3rem;
  color: yellow;
  cursor: pointer;
}

.contactWrapper {
  position: relative;
  /* height: 40rem; */
  padding: 2rem;
  margin: 3rem 0rem;
  margin-bottom: 0rem;
  display: flex;
}

.wrapLeft {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.leftInnerWrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.wrapRight {
  position: relative;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lottieAnimation {
  width: 30rem;
  height: 30rem;
}

.infoList {
  position: relative;
  width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.listItem {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  color: rgb(218, 218, 218);
  font-family: "Open Sans";
  /* background-color: rgba(0, 0, 255, 0.644); */
  border-radius: 0.4rem;
  font-size: 18px;
  padding: 0.5rem;
}

.miniIcon {
  font-size: 2rem;
  color: white !important;
}

.formWrap {
  position: relative;
  width: 25rem;
  height: 25rem;
  background-color: red;
}

.formWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0rem;
  padding-bottom: 10rem;
  margin-top: 7rem;
  color: white;
  font-family: "Open Sans";
  font-size: 3rem;
  font-weight: bold;
}

.copyField {
  resize: none;
  width: 100%;
  height: 15rem;
  border: 1px solid rgb(121, 121, 121);
  background-color: transparent;
  outline: none;
  border-radius: 0.4rem;
  font-family: "Open Sans";
  padding: 1rem;
  font-size: 18px;
  color: white;
}

.copyField::placeholder {
  color: rgb(207, 206, 206);
}

.textInput {
  width: 100%;
  height: 3rem;
  border: 1px solid rgb(121, 121, 121);
  background-color: transparent;
  outline: none;
  border-radius: 0.4rem;
  padding-left: 1rem;
  font-size: 18px;
  color: white;
  font-family: "Open Sans";
}

.textInput::placeholder {
  color: rgb(182, 180, 180);
  font-family: "Open Sans";
}

.ctaBtn {
  width: 100%;
  height: 3rem;
  border: 1px solid rgb(121, 121, 121);
  background-color: rgb(27, 25, 25);
  outline: none;
  border-radius: 0.4rem;
  padding-left: 1rem;
  font-size: 18px;
  margin: 0.5rem 0rem;
  text-align: center;
  color: white;
  font-family: "Open Sans";
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.ctaBtn:hover {
  background-color: rgb(58, 57, 57);
}

.formBg {
  width: 25rem;
  min-height: 29rem;
  /* background-color: rgb(56, 56, 56); */
  position: relative;
  margin: 0 auto;
  left: -20px;
  border-radius: 0.4rem;
}

.contactForm {
  width: 25rem;
  min-height: 29rem;
  position: relative;
  left: 7px;
  top: -7px;
  border-radius: 0.8rem;
  background-color: black;
  box-shadow: 6px 5px 10px gray;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.error {
  color: red;
  font-family: "Open Sans";
  position: relative;
  padding-left: 0.5rem;
  font-size: 16px;
}

@media (max-width: 1000px) {
  .contactWrapper {
    flex-direction: column;
  }
  .wrapLeft {
    width: 100%;
  }
  .wrapRight {
    margin-top: 5rem;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .hero {
    padding-left: 0rem;
    text-align: center;
  }
}
@media (max-width: 430px) {
  .infoList {
    left: 2rem;
    position: relative;
  }
  .wrapRight {
    margin-top: 0rem;
    width: 100%;
  }
  .formWrapper {
    margin-top: 0rem;
    padding-top: 2.5rem;
    padding-bottom: 5rem;
    padding-right: 0px;
  }
  .formBg {
    left: -0.5rem;
    transform: scale(0.9);
  }
  .wrapLeft {
    margin-bottom: 3rem;
  }
  .contactWrapper {
    padding-bottom: 0rem;
  }
}
