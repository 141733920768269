.kamermodal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.917);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  padding-top: 10px;
  overflow-x: hidden;
}

.modal {
  width: 40rem;
  /* height: 53rem; */
  background-color: red;
  border-radius: 20px 5px 20px 5px;
  background-color: rgb(19, 24, 43);
  background: linear-gradient(
    50deg,
    rgb(19, 24, 43),
    rgb(16, 19, 31),
    rgb(13, 16, 26)
  );
  overflow: hidden;
}
.modalWrap {
  position: absolute;
  width: 100vw;
  max-width: 40rem;
  background: linear-gradient(
    50deg,
    rgb(19, 24, 43),
    rgb(16, 19, 31),
    rgb(13, 16, 26)
  );
  overflow-x: hidden;
  top: 0;
}
.hero {
  color: yellow;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.roomImg {
  position: relative;
  width: 96%;
  left: 2%;
  height: 20rem;
  object-fit: cover;
  border-radius: 0.4rem;
}

.buttonBox {
  width: 100%;
  display: flex;

  justify-content: center;
  gap: 0.5rem;
  top: -2.5rem;
  position: relative;
}

.imgWrap {
  height: 20.6rem;
}

.buttonBox button {
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgb(0, 0, 0);
  padding: 1px;
  cursor: pointer;
}

.activeButton {
  background-color: rgb(19, 24, 43) !important;
  background-color: white !important;
}

.prijsWrap {
  position: relative;
  background-color: rgb(25, 32, 59);
  border-radius: 0.4rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.5rem;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  width: 32%;
}

.icon {
  color: rgb(163, 163, 163);
  font-size: 18px;
}
.personCount {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.priceBlock {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  flex-direction: column;
}

.copyTxt {
  color: white;
  font-family: "Open Sans";
  font-weight: bold;
}

.copyTitle {
  color: rgb(163, 163, 163);
  font-family: "Open Sans";
  display: block;
  font-weight: bold;
  text-align: start;
}

.personWrapper {
  display: flex;
  gap: 1rem;
  /* background-color: red; */
  position: relative;
  padding-left: 2rem;
}

.totalWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 9rem;
  gap: 1rem;
  /* background-color: red; */
}

.euro {
  color: rgb(163, 163, 163);
  font-size: 1.5rem;
  top: 8px;
  position: relative;
}

.bottomWrap {
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  padding: 0rem 10px;
}

.exclamation {
  color: yellow;
  position: relative;
}

.toeslagWrap {
  position: relative;
  background-color: rgb(25, 32, 59);
  border-radius: 0.4rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.5rem;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  width: 32%;
}

.toeslagTop {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.toeslagBottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0.8rem;
  left: -5px;
  position: relative;
}

.euro2 {
  color: rgb(163, 163, 163);
  font-size: 1.5rem;
  position: relative;
}

.pppnCol {
  display: flex;
  /* flex-direction: column; */
  padding-left: 1.5rem;
  gap: 0.5rem;
}

.pppnTxt {
  color: rgb(163, 163, 163);
  font-family: "Open Sans";
}

.ontbijtWrap {
  position: relative;
  background-color: rgb(25, 32, 59);
  border-radius: 0.4rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.5rem;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
  width: 32%;
}

.forkIcon {
  color: rgb(163, 163, 163);
  font-size: 1.2rem;
  position: relative;
}

.descriptionWrap {
  display: flex;
  width: 103.8%;
  height: 400px;
}

.copyBox {
  width: 65%;
  height: 100%;
  padding: 10px;
  padding-right: 7px;
  height: 100%;

  position: relative;
}

.utilitiesBox {
  width: 44%;
  /* background-color: rgb(0, 68, 255); */
  /* height: 100%; */
  padding: 10px;
  padding-left: 5px;

  position: relative;
  margin-right: 12px;
}

.copyTextBox {
  width: 100%;
  background-color: rgb(25, 32, 59);
  border-radius: 0.4rem;
  height: 400px;
}

.boxTitle {
  font-family: "Open Sans";
  text-align: center;
  color: rgb(163, 163, 163);
  font-size: 18px;
  font-weight: bold;
  padding-top: 3px;
}

.roomCopy {
  color: white;
  font-family: "Open Sans";
  padding: 10px;
  font-size: 16px;
}

.voorzienList {
  list-style-type: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.55rem;
  margin-top: 5px;
  padding-bottom: 37px;
  overflow-y: scroll;
  padding-top: 5px;
}

.voorziening {
  color: white;
  font-family: "Open Sans";
  background-color: rgb(34, 46, 90);
  border-radius: 0.4rem;
  padding: 10px;
  font-size: 14px;
  width: 95%;
  left: 2.5%;
  position: relative;
}

.utilitiesTextBox {
  width: 95%;
  background-color: rgb(25, 32, 59);
  border-radius: 0.4rem;
  height: 100%;
  border-radius: 0.4rem;
  overflow: hidden;
  height: 400px;
}

.ctaBox {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.reserverenCta {
  background-color: rgb(25, 32, 59);
  color: yellow;
  font-family: "Open Sans";
  padding: 0.4rem 3rem;
  border-radius: 0.4rem;
  font-size: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.3s ease-in-out;
  border: 1px solid yellow;
  background-color: yellow;
  color: black;
}

.reserverenCta:hover {
  background-color: black;
  color: yellow;
}
.euroWrapper {
  position: absolute;
  left: -3px;
  display: none;
}

.closeRow {
  /* background-color: cyan; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  width: 40rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
}

.closeBtn {
  font-size: 2rem;
  color: rgb(200, 200, 200);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.closeBtn:hover {
  color: white;
}

@media (max-width: 680px) {
  .modal {
    margin: 0rem 20px;
  }
  .closeRow {
    width: 100vw;
    padding-right: 2.5rem !important;
  }
}

@media (max-width: 680px) {
  .personWrapper {
    padding-left: 0rem;
  }
  .hero {
    font-size: 28px;
  }

  .utilitiesBox {
    padding-right: 0rem;
  }
}

@media (max-width: 620px) {
  .personWrapper {
    padding-left: 0rem;
  }
  .bottomWrap {
    flex-direction: column;
    gap: 0.5rem;
  }
  .prijsWrap {
    width: 100%;
  }
  .toeslagWrap {
    width: 100%;
  }
  .ontbijtWrap {
    width: 100%;
  }

  .toeslagTop {
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .totalWrap {
    width: 100%;
  }
  .euroWrapper {
    position: relative;
  }
  .toeslagBottom {
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .imgWrap {
    height: 15rem;
    margin-bottom: 10px;
  }
  .roomImg {
    height: 100%;
  }
}

@media (max-width: 600px) {
  .hero {
    font-size: 20px;
  }
  .closeBtn {
    position: relative;
    top: -3px;
  }
}
@media (max-width: 500px) {
  .voorzienList {
    /* margin-top: 10px; */
    width: 107%;
    /* padding-bottom: 7.8rem !important; */
  }
  .roomCopy {
    font-size: 14px;
  }
  .copyBox {
    padding-right: 0px;
  }
  .boxTitle {
    font-size: 16px;
  }
  .utilitiesBox {
    margin-right: 8px;
  }
  .ctaBox {
    margin-bottom: 2rem;
  }
  .voorziening {
    width: 91%;
  }
}
