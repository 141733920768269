.footer {
  position: relative;
  height: 25rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid rgb(37, 37, 37);
  background-color: black;
}

.logo {
  width: 5rem;
}

.reserveerCtaSpan {
  color: yellow;
  font-size: 24px;
  margin-top: 3rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.reserveerCtaSpan:hover {
  transform: scale(1.15);
}

.border {
  width: 8rem;
  border-bottom: 1px solid gray;
}

.copyRight {
  color: rgb(130, 124, 124);
  font-family: "Open Sans";
  margin-top: 1rem;
}
