.cookieBackdrop {
  width: 100vw;
  height: 100vh;
  z-index: 400;
  position: fixed;
  inset: 0;
  z-index: 5000;
  background-color: rgba(0, 0, 0, 0.85);
  display: none;
}

.cookiebanner {
  position: relative;
  background-color: rgb(21, 26, 35);
  z-index: 100;
  border-radius: 0.375rem;
  border: 1px solid rgb(33, 40, 54);
  background-image: radial-gradient(
    ellipse 100% 100% at 50% 75%,
    rgba(37, 99, 235, 0.07),
    rgba(37, 99, 235, 0) 60%
  );
  user-select: none;
  box-shadow: 0px 0px 5px black;
  flex-direction: column;
  margin: 0rem 0.25rem;
  margin-bottom: 4px;
  padding: 0px 1rem;
}

.bannerSpan {
  color: gray;
  padding: 0px 1.5rem;
  font-size: 18px;
  padding-top: 0.5rem;
}

.prefCta {
  border: none;
  outline: none;
  color: gray;
  border-radius: 0.375rem;
  background-color: rgb(21, 26, 35);
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid rgb(33, 40, 54);
  user-select: none;
}

.prefCta:hover {
  background-color: rgb(18, 18, 18);
  color: rgb(203, 203, 203);
}

.white {
  font-size: 18px;
  color: white;
  font-weight: 500;
}

.acceptCta {
  border: none;
  outline: none;
  color: white;
  border-radius: 0.375rem;
  background-color: #0084ff;
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid rgb(33, 40, 54);
  margin-right: 1rem;
  transition: all 0.2s ease-in-out;
  user-select: none;
}

.acceptCta:hover {
  background-color: rgb(58, 58, 194);
}

.declineCta {
  border: none;
  outline: none;
  color: white;
  border-radius: 0.375rem;

  background-color: rgb(33, 40, 54);
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid rgb(33, 40, 54);
  transition: all 0.2s ease-in-out;
  user-select: none;
}

.declineCta:hover {
  background-color: var(--input);
}

.readMore {
  font-size: 14px;
  position: relative;
  bottom: 0.5rem;
  user-select: none;
  cursor: pointer;
  color: #0084ff;
  transition: all 0.2s ease-in-out;
}

.readMore:hover {
  color: rgb(104, 104, 254);
}

.bannerWrap {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.prefsBox {
  width: 100%;
  margin: auto;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0rem;
  padding-bottom: 0.2rem;
  max-width: 756px;
}

.mainBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 6rem;
}

.prefItem {
  width: 91%;
  border: 1px solid rgb(33, 40, 54);
  position: relative;
  border-radius: 0.375rem;
  background-color: rgb(21, 26, 35);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.prefTitle {
  display: block;
  color: gray;
}

.prefDesc {
  color: white;
  margin-right: 5px;
}

.prefTextBlock {
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 0.5rem;
}

.toggleBox {
  height: 100%;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggleWrap {
  display: flex;
  width: 6rem;
  min-width: 3.5rem;
  margin-right: 15px;
  height: 2.5rem;
  border-radius: 2rem;
  border: 1px solid rgb(33, 40, 54);
  background-color: var(--input);
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.1s ease-in-out;
}

.flipped {
  transform: translateX(3.4rem);
}

.toggleWrap:hover {
  box-shadow: 0px 0px 3px rgba(128, 128, 128, 0.608);
}

.toggleDot {
  height: 1.75rem;
  width: 1.75rem;
  background-color: rgb(33, 40, 54);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.reactIcon {
  font-size: 30px;
  color: rgb(33, 40, 54);
  margin: 0px 1rem;
  color: rgb(142, 142, 142);
  transition: all 0.3s ease-in-out;
}

.saveConfig {
  width: 7rem;
  height: 2.5rem;
  border: 1px solid var(--input);
  border-radius: 0.375rem;
  background-color: transparent;
  color: white;
  bottom: 0.4rem;
  position: relative;
  background-color: rgb(16, 16, 16);
  cursor: pointer;

  transition: all 0.3s ease-in-out;
}

.saveConfig:hover {
  background-color: black;
}

.visible {
  background-color: rgba(0, 0, 0, 0.2) !important;
  height: 7vh;
  bottom: 0;
  position: fixed;
}

.btnsWrap {
  display: flex;
}

@media (max-width: 850px) {
  .bannerSpan,
  .white {
    font-size: 16px;
    line-height: 16px;
  }
  .readMore {
    position: relative;
    top: 0.2rem;
  }
  .acceptCta {
    margin-right: 0.5rem;
  }
}

@media (max-width: 600px) {
  .bannerSpan,
  .white {
    font-size: 13px;
    line-height: 13px;
  }
  .readMore {
    position: relative;
    top: 0.2rem;
  }
  .acceptCta {
    margin-right: 0.5rem;
  }
  .btnsWrap {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  .acceptCta {
    margin-right: 0;
  }
}

@media (max-width: 430px) {
  .bannerSpan {
    padding: 0rem 0.2rem;
    text-align: center;
  }
  .prefDesc {
    font-size: 14px;
    padding-right: 5px;
    padding-left: 0px;
  }
  .reactIcon {
    margin: 0rem 0.5rem;
  }
  .prefTextBlock {
    padding-left: 0;
  }

  .toggleWrap {
    height: 2rem;
    width: 5.7rem;
  }

  .toggleDot {
    height: 1.5rem;
    width: 1.5rem;
    background-color: rgb(33, 40, 54);
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
  }
}
