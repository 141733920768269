@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
.reviews {
  background-color: black;
  padding-bottom: 10rem;
  overflow-x: hidden !important;
}

.hero {
  font-size: 40px;
  color: yellow;
  padding-left: 7rem;
}

.avgRatedBox {
  width: 100%;
  /* background-color: rgba(255, 255, 255, 0.109); */
  padding-top: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.subHeroTitle {
  color: rgb(191, 191, 191);
  font-size: 48px;
}

.rating {
  color: white;
  display: block;
  font-size: 48px;
}

.modalsGrid {
  /* background-color: green; */
  padding-top: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding-bottom: 5rem;
}

.reviewTile {
  width: 23rem;
  min-height: 17.5rem;
  background-color: black;
  background: linear-gradient(20deg, rgb(58, 55, 55), rgb(58, 55, 55), black);
  border-radius: 2rem;
  position: relative;
  z-index: 5;
  margin-bottom: 2rem;
}
.reviewTile:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 24px solid rgb(58, 55, 55);
  border-right: 12px solid transparent;
  border-top: 12px solid rgb(58, 55, 55);
  border-bottom: 20px solid transparent;
  left: 32px;
  bottom: -29.5px;
}

.revToprow {
  position: relative;
  height: 5rem;
  /* background-color: red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;
  padding-top: 0.5rem;
}

.userImg {
  border-radius: 50%;
  height: 3.25rem;
}

.userName {
  color: white;
  font-size: 18px;
  font-weight: bold;
  max-width: 10rem;
  font-family: "Open Sans";
}

.firstBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
  gap: 0.3rem;
}

.jobType {
  color: rgb(197, 197, 197);
  font-family: "Open Sans";
}

.userRating {
  width: 8.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  letter-spacing: 1px;
  font-family: "Open Sans";
  position: absolute;
  left: 15rem;
  top: -0.5rem;
}

.userCopyWrap {
  width: 100%;
  height: calc(100% - 5rem);
  padding: 1rem;
}

.userCopy {
  color: white;
  font-family: "Open Sans";
}

@media (max-width: 1240px) {
  .modalsGrid {
    gap: 0rem;
  }

  .reviewTile {
    transform: scale(0.9) !important;
  }
}

@media (max-width: 1080px) {
  .userRating {
    left: 13rem;
  }
}

@media (max-width: 1000px) {
  .modalsGrid {
    flex-direction: column;
    gap: 3rem;
  }
  .reviewTile {
    transform: scale(1) !important;
  }
  .userRating {
    left: 15rem;
  }
}

@media (max-width: 900px) {
  .subHeroTitle {
    font-size: 7vw;
    text-align: center;
  }
  .rating {
    font-size: 8vw;
    font-weight: bold;
  }
}

@media (max-width: 500px) {
  .avgRatedBox {
    padding-top: 8rem;
  }
  .reviews {
    padding-bottom: 6rem;
  }
  .modalsGrid {
    padding-top: 15rem;
  }
}

@media (max-width: 400px) {
  .reviewTile {
    transform: scale(0.9) !important;
  }
}
