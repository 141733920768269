.omgeving {
  width: 100%;
  background: linear-gradient(
    180deg,
    black,
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    black
  );
  padding: 5rem 0rem;

  overflow-x: hidden !important;
}

.hero {
  font-size: 40px;
  color: yellow;
  padding-left: 7rem;
  padding-top: 15rem;
}

.nlkaart {
  position: absolute;
  transition: all 0.3s ease;
}

.nlwrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerKaartwrap {
  position: relative;
  top: -11rem;
}

.pointer {
  position: relative;
  background-color: white;
  border-radius: 50%;
  z-index: 10;
  width: 50px;
  height: 50px;
  filter: blur(10px);
  left: 395px;
  top: 215px;
  opacity: 0.3;
  cursor: pointer;
}

.pointer:active + .nlkaart {
  padding-top: 7rem;
  padding-right: 9rem;
  transform: scale(2) !important;
}

.omgevingcopy {
  color: yellow;
  width: 38rem;
  padding-left: 7rem;
  font-size: 18px;
  letter-spacing: 3px;
  padding-bottom: 20rem;
}

@media (max-width: 1200px) {
  .nlwrap {
    position: relative;
    /* left: -3rem !important; */
  }

  .omgevingcopy {
    padding-left: 3rem;
  }
  .hero {
    padding-left: 3rem;
  }
}
@media (max-width: 1150px) {
  .innerKaartwrap {
    position: relative;
    top: 20rem;
    left: -20rem;
  }
  .omgeving {
    height: 80rem;
    overflow: hidden;
  }
  .hero {
    padding: 0;
    text-align: center;
    font-size: 30px;
  }
  .omgevingcopy {
    padding: 0;
    margin: 0 auto;
    width: auto;
    padding: 0rem 5rem;
  }
}

@media (max-width: 780px) {
  .innerKaartwrap {
    position: relative;
    top: 25rem;
    left: -10rem;
  }
  .omgevingcopy {
    padding: 0;
    margin: 0 auto;
    width: auto;
    padding: 0rem 2.5rem;
  }
}

@media (max-width: 500px) {
  .omgeving {
    padding-top: 0rem;
  }
  .omgevingcopy {
    padding: 0;
    margin: 0 auto;
    width: auto;
    padding: 0rem 1.5rem;
  }
  .innerKaartwrap {
    position: relative;
    top: 30rem;
    left: 10rem;
  }
  .nlkaart {
    width: 25rem;
    top: 5rem;
    left: -21rem;
  }
  .pointer {
    left: -80px;
    top: 185px;
    pointer-events: none;
  }
  .omgeving {
    padding-bottom: 0rem;
  }
}
