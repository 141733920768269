@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
.portfolio {
  position: relative;
  text-align: start;
  display: flex;

  flex-direction: column;
  background-color: black;
  background: linear-gradient(
    180deg,
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    rgb(2, 32, 2),
    black
  );
  padding-bottom: 5rem;
}

.activityHeader {
  padding-top: 6rem;
  background-image: url("../../assets/omgeving/Steenwijkhaven.webp");
  height: 40rem;
  /* background-size: 10%; */
  background-size: cover;
  background-repeat: no-repeat;
}

.portfolio h1 {
  color: yellow;
  margin-left: 6rem;
}

.contentWrap {
  position: relative;
  padding-top: 5rem;
  padding: 5rem 3rem;
}

.ontDekDiv {
  position: relative;
  width: 100%;
  height: 6rem;
  background-color: rgba(0, 0, 0, 0.197);
  top: 25.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heroTxt {
  color: yellow;
  background-color: rgb(2, 32, 2);
  padding: 3rem;
  border-top-left-radius: 4rem;
  border-top-right-radius: 4rem;
}

.chopperModal {
  background-image: url("../../assets//omgeving/Echopper.webp");
  background-position: center;
  background-size: cover;
  height: 25rem;
  width: 30%;
  border-radius: 0.8rem;
  opacity: 0.8;
  overflow: hidden;
  cursor: pointer;
}

.innerChopperModal {
  width: 100%;
  height: 100%;
  transform: translateY(85%);
  cursor: pointer;
  transition: all 1s ease-in-out;
}

.innerChopperModal h2 {
  text-align: center;
  width: 100%;
  color: white;
  text-shadow: 0px 0px 3px black;
  cursor: pointer;
  padding-top: 1rem;
}

.chopperModal:hover .innerChopperModal {
  transform: translateY(0%);
  background-color: rgba(0, 0, 0, 0.788);
}

.innerLinkModal h2 {
  text-align: center;
  width: 100%;
  color: white;
  text-shadow: 0px 0px 3px black;
  cursor: pointer;
  padding-top: 1rem;
}

.linksModal:hover .innerLinkModal {
  transform: translateY(0%);
  background-color: rgba(0, 0, 0, 0.788);
}

.conditionsList {
  position: relative;
  width: 100%;
  height: 22rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}

.linksList {
  position: relative;
  width: 100%;
  height: 22rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.innerWrap {
  position: relative;
  width: 100%;
  height: 22rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}

.innerWrap a {
  text-decoration: none;
  color: rgb(204, 204, 204);
}

.innerWrap a {
  text-decoration: none;
}

.innerWrap a:hover .linkSpan {
  color: white !important;
}

.conditionSpan {
  color: white;
  font-family: "Open Sans";
  font-size: 18px;
}

.linkSpan {
  color: rgb(204, 204, 204);
  font-family: "Open Sans";
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.bookCta {
  font-family: "Open Sans";
  font-size: 1.5rem;
  padding: 0.5rem 2.5rem;
  border-radius: 0.4rem;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: white;
  color: black;
  margin-top: 2.5rem;
  transition: all 0.2s ease-in-out;
}

.bookCta:hover {
  background-color: black;
  color: white;
  box-shadow: 0px 0px 3px black;
}

.contentTopBlock {
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.leftAlign {
  display: block;
  display: flex;
  flex-direction: column;
}

.activiteitenModal {
  height: 25rem;
  width: 60%;
  border-radius: 0.8rem;
  opacity: 0.8;
  overflow: hidden;
  background-color: black;
  display: flex;
  flex-direction: row-reverse;
}

.activLeft {
  position: relative;
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 1rem;
}

.regioLeft {
  position: relative;
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 1rem;
}

.acthero {
  color: white;
  position: relative;
}

.regioHero {
  color: white;
  position: relative;
}

.itemList {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  gap: 0.5rem;
  list-style-type: none;
}

.itemList a {
  color: white;
  text-decoration: none;
}

.listItem {
  color: rgb(222, 222, 222);
  font-family: "Open Sans";
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}

.listItem:hover {
  color: white;
}
.listItem:hover .arrow {
  opacity: 1;
}

.activRight {
  position: relative;
  width: 100%;
  /* background-color: red; */
  height: 100%;
}

.arrow {
  position: relative;
  opacity: 0;
  width: 16px;
}

.cycleImg {
  position: relative;
  height: 95%;
  top: 2.5%;
  left: 2%;
  width: 96%;
  object-fit: cover;
  border-radius: 0.4rem;
}

.cycleImg2 {
  position: relative;
  height: 95%;
  top: 2.5%;
  left: 2%;
  width: 97%;
  object-fit: cover;
  border-radius: 0.4rem;
}

.regioBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  padding-top: 2.5rem;
}
.regioModal {
  height: 25rem;
  width: 92%;
  border-radius: 0.8rem;
  opacity: 0.8;
  overflow: hidden;
  background-color: black;
  display: flex;
  flex-direction: row;
  margin-bottom: 2.5rem;
}

.regioModal .regioLeft {
  padding-left: 1rem;
}

.activiteitenModal .itemList {
  padding-left: 0rem;
  align-items: flex-start;
  justify-content: center;
}

.regioLeft .itemList {
  padding-left: 0rem;
  align-items: flex-start;
  margin-right: 0.5rem;
}

.linksModal {
  background-image: url("../../assets/activiteiten/SteenwijkAir.webp");
  background-position: center;
  background-size: cover;
  height: 25rem;
  width: 30%;
  border-radius: 0.8rem;
  opacity: 0.8;
  overflow: hidden;
}

.innerLinkModal {
  width: 100%;
  height: 100%;
  transform: translateY(85%);
  transition: all 1s ease-in-out;
}

@media (max-width: 1400px) {
  .contentWrap {
    padding: 5rem 0rem;
  }
  .heroTxt {
    color: yellow;
    background-color: rgb(2, 32, 2);
    padding: 3rem;
    border-top-left-radius: 4rem;
    border-top-right-radius: 4rem;
  }
}

@media (max-width: 1200px) {
  .heroTxt {
    color: yellow;
    background-color: rgb(2, 32, 2);
    padding: 2.5rem;
    border-top-left-radius: 4rem;
    border-top-right-radius: 4rem;
    font-size: 20px;
  }
  .contentTopBlock {
    gap: 0.75rem;
  }
  .contentWrap {
    padding: 2.5rem 15px;
  }
  .chopperModal {
    width: 40%;
  }
  .activiteitenModal {
    width: 60%;
  }
  .linksModal {
    width: 40%;
  }
  .dinerenModal {
    width: 60%;
  }
  .regioModal {
    width: 100%;
  }
  .acthero {
    font-size: 18px;
  }
  .listItem {
    font-size: 14px;
  }
  .regioHero {
    text-align: center;
    font-size: 18px;
  }
  .innerLinkModal h2 {
    font-size: 18px;
    text-shadow: 0px 0px 5px black;
  }
}

@media (max-width: 800px) {
  .contentTopBlock {
    flex-direction: column;
  }
  .chopperModal {
    width: 100%;
    margin-bottom: 2.5rem;
  }
  .activiteitenModal {
    width: 100%;
  }
  .linksModal {
    width: 100%;
    margin-top: 2.5rem;
  }
}

@media (max-width: 500px) {
  .heroTxt {
    text-align: center;
  }
  li svg {
    display: none;
  }
  .listItem {
    padding-right: 5px;
  }
  .regioLeft {
    width: 40%;
  }
  .activLeft .itemList {
    padding-left: 5px;
  }
  .activityHeader {
    background-position: 50%;
  }
}
